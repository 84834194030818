import React, { ChangeEvent, useRef, useState } from 'react';

import WrapperPage from './WrapperPage';
import useAPISubscription from '../hooks/api/useAPISubscription';
import Button from '../components/Button';
import FormGroup from '../slices/FormGroup';
import DataTable from '../components/DataTable';
import Bullet from '../slices/Bullet';
import { UilSpinner } from '@iconscout/react-unicons';

function HighlightText({ text }: { text: string }) {
	return <span className="font-mono text-xs bg-gray-200 rounded-md px-2 py-[1px]">{text}</span>;
}

const MAX_ROWS = 10;

export default function ImportPage() {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const { error, loading, uploading, exportTemplate, importSubscriptions, clearError, pendingInformation } =
		useAPISubscription({ pendingInformationLimit: MAX_ROWS });

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null;
		setSelectedFile(file);
	};

	function handleUploadCSV() {
		if (!selectedFile) return;
		importSubscriptions(selectedFile);
	}

	return (
		<WrapperPage title="Subscription Importer" error={error} onAlertClose={clearError}>
			<div className="mx-4 flex flex-col gap-4 mb-6">
				<FormGroup title="Subscriptions Pending Information">
					{loading && (
						<div className="flex items-center gap-2">
							<UilSpinner className="animate-spin" />
							<span className="text-sm">Checking for subscriptions...</span>
						</div>
					)}
					{!loading && pendingInformation && pendingInformation.total > 0 && (
						<div className="flex flex-col gap-4">
							<DataTable
								fields={[
									{
										title: 'Plan',
										property: 'service_plan_name',
									},
									{
										title: 'NAS',
										property: 'infra_nas_pool.infra_nas.name',
										extractor: (row) => row.infra_nas_pool.infra_nas.name,
									},
									{ title: 'PPPoE User', property: 'connectivity_user' },
									{ title: 'Due Day', property: 'due_day', extractor: (row) => row.due_day || '-' },
									{
										title: 'Price',
										property: 'service_plan_price',
										extractor: (row) => row.due_day || '0',
									},
								]}
								rows={pendingInformation.subscriptions}
								style="condensed"
							/>
							{pendingInformation.total > MAX_ROWS && (
								<div className="text-center text-xl font-mono flex flex-col gap-4">
									<span>...</span>
									<div className="flex justify-center">
										<Bullet
											type="warning"
											title={`${pendingInformation.total} more subscriptions need additional information.`}
										/>
									</div>
								</div>
							)}
						</div>
					)}
					{!loading && pendingInformation && pendingInformation.total === 0 && (
						<div className="flex">
							<Bullet type="success" title="All subscriptions are complete" />
						</div>
					)}
				</FormGroup>
				{!loading && pendingInformation && pendingInformation.total > 0 && (
					<FormGroup title="How to solve - Step by Step">
						<div className="flex flex-col gap-4">
							<ul className="pl-4 list-decimal flex flex-col gap-4 text-justify">
								<li>
									First,{' '}
									<Button variant="link" text="click here to download" onClick={exportTemplate} /> the
									CSV template containing the current subscriptions that need additional information.
								</li>
								<li>
									<p>
										Edit the file by adding the due date the customer uses to pay for your ISP
										services, and optionally, add the service price.
									</p>
									<p>
										The CSV is composed of 5 fields:{' '}
										<HighlightText text="nas, plan, connectivity_user, due_day, service_plan_price" />
										.
									</p>
									<p>
										<HighlightText text="nas, plan, connectivity_user" /> will be pre-filled.
									</p>
									<p>
										<HighlightText text="due_day" /> should be filled with a positive integer from 1
										to 31.
									</p>
									<p>
										<HighlightText text="service_plan_price" /> can optionally be filled with the
										price charged for the service; otherwise, it will automatically use the plan
										price.
									</p>
									<p>
										<i>
											Avoid using decimal points. For example, $12.99 should be entered as 1299.
										</i>
									</p>
								</li>
								<li>
									Save it as a new CSV file. The upload only accepts .csv files, so avoid other
									formats like .xls or .xlsx.
								</li>
								<li>
									<input
										type="file"
										ref={fileInputRef}
										onChange={handleFileChange}
										accept=".txt, .csv"
										style={{ display: 'none' }}
									/>
									<Button
										variant="link"
										text={
											selectedFile
												? `${selectedFile.name} file selected`
												: 'Click here to select the file'
										}
										onClick={() => fileInputRef.current?.click()}
									/>
								</li>
							</ul>
							<div className="flex gap-2">
								<Button
									text="Upload CSV"
									style="roundedOutline"
									icon={{ type: uploading ? 'UilSpinner' : 'UilCloudUpload', spin: uploading }}
									size="xs"
									onClick={handleUploadCSV}
									disabled={!selectedFile || uploading}
								/>
								<Button
									text="Clear file"
									style="roundedOutline"
									size="xs"
									onClick={() => setSelectedFile(null)}
									disabled={!selectedFile || uploading}
								/>
							</div>
						</div>
					</FormGroup>
				)}
			</div>
		</WrapperPage>
	);
}
