import { IconType } from '@iconscout/react-unicons';
import { ButtonIcon } from '../../components/Button';
import { SubscriptionHistoryModificationType } from '../../shared/models/Subscription';
import { TailwindTextColorClass } from '../../shared/tailwind';

export const CLOCK_ICON: ButtonIcon = {
	type: 'UilClock',
};

export const PLAY_ICON: ButtonIcon = {
	type: 'UilPlay',
};

export const PAUSE_ICON: ButtonIcon = {
	type: 'UilPause',
};

export const RESUME_ICON: ButtonIcon = {
	type: 'UilForward',
};

export const STOP_ICON: ButtonIcon = {
	type: 'UilSquareFull',
};

export const HISTORY_ICON: {
	[key in SubscriptionHistoryModificationType]: { type: IconType; color: TailwindTextColorClass };
} = {
	Created: { type: 'UilPlusCircle', color: 'text-light' },
	Activate: { type: PLAY_ICON.type, color: 'text-success' },
	Suspend: { type: PAUSE_ICON.type, color: 'text-warning' },
	Resume: { type: RESUME_ICON.type, color: 'text-success' },
	Terminate: { type: STOP_ICON.type, color: 'text-danger' },
	Updated: { type: 'UilSync', color: 'text-info' },
};
