import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Subscription } from './models/Subscription';
import { TFunction } from 'i18next';

export function generateRandomId(length: number): string {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
}

export function toLocaleNumberString(n: number, locale: string = navigator.language, decimalDigits: number = 2) {
	return n.toLocaleString(locale, {
		maximumFractionDigits: decimalDigits,
		minimumFractionDigits: decimalDigits,
	});
}

export function timeSince(dateString: string): string {
	const date = new Date(dateString);
	const now = new Date();
	const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

	const intervals: { [key: string]: number } = {
		year: 31536000, // 60 * 60 * 24 * 365
		month: 2592000, // 60 * 60 * 24 * 30
		day: 86400, // 60 * 60 * 24
		hour: 3600, // 60 * 60
		minute: 60,
		second: 1,
	};

	for (const [key, value] of Object.entries(intervals)) {
		const interval = Math.floor(seconds / value);
		if (interval >= 1) {
			if (key === 'day' && interval === 1) return 'yesterday'; // Special case
			return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
		}
	}
	return 'just now';
}

export function toLocaleLongDateString(isoDateString: string) {
	const userLocale = navigator.language || 'en-US';
	const date = new Date(isoDateString);
	const localeDateString = date.toLocaleDateString(userLocale, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timeZone: 'UTC',
	});
	return localeDateString;
}

export function toLocaleShortDateString(isoDateString: string) {
	const userLocale = navigator.language || 'en-US';
	const date = new Date(isoDateString);
	const localeDateString = date.toLocaleDateString(userLocale, { timeZone: 'UTC' });
	return localeDateString;
}

export function dueDateValueExtractor(t: TFunction, form: Subscription) {
	const dueDate = form.due_day;
	if (!dueDate) return '';

	const suffix = t(`utils.due-date-extractor.suffix.${dueDate}`, {
		defaultValue: t('utils.due-date-extractor.suffix.default'),
	});

	return `${t('utils.due-date-extractor.label')} ${dueDate}${suffix}`;
}

export function getCardBrandLogoUrl(cardBrand: string): string {
	const cardBrandLogos: { [key: string]: string } = {
		visa: '/imgs/cards/light/visa.png',
		mastercard: '/imgs/cards/light/mastercard.png',
		amex: '/imgs/cards/light/amex.png',
		discover: '/imgs/cards/light/discover.png',
		jcb: '/imgs/cards/light/jcb.png',
		diners: '/imgs/cards/light/diners.png',
		unionpay: '/imgs/cards/light/unionpay.png',
	};

	const normalizedBrand = cardBrand.toLowerCase();
	return cardBrandLogos[normalizedBrand] || '/imgs/cards/light/default.png';
}

export function getUTCDate(daysOffset = 0, referenceDate: Date = new Date()): string {
	const utcDate = new Date(
		Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), referenceDate.getUTCDate() + daysOffset),
	);
	return utcDate.toISOString();
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
