import React, { UilAlignCenterAlt } from '@iconscout/react-unicons';

import UserMenu from './UserMenu';
import TenantMenu from './TenantMenu';

interface HeaderProps {
	open: boolean;
	fold: boolean;
	setOpen: (open: boolean) => void;
	setFold: (fold: boolean) => void;
}

export default function Header({ open, fold, setOpen, setFold }: HeaderProps) {
	return (
		<header className="bg-white w-full px-4 shadow-sm">
			<div className="lg:hidden h-16 flex flex-grow items-center justify-between">
				<UilAlignCenterAlt className="cursor-pointer" onClick={() => setOpen(!open)} />
				<UserMenu />
			</div>
			<div className="max-lg:hidden h-16 w-full flex items-center justify-between">
				<div className="flex items-center justify-center gap-4">
					<UilAlignCenterAlt className="cursor-pointer" onClick={() => setFold(!fold)} />
					<TenantMenu />
				</div>
				<UserMenu />
			</div>
		</header>
	);
}
