import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';
import { SubscriptionPendingInformation } from '../../shared/models/SubscriptionPendingInformation';

export class APISubscriptionsOptions {
	mustFetchPendingInformation: boolean = true;
	pendingInformationLimit: number = 10;

	constructor(options?: Partial<APISubscriptionsOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPISubscription(options?: Partial<APISubscriptionsOptions>) {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const { mustFetchPendingInformation, pendingInformationLimit } = new APISubscriptionsOptions(options);

	const [pendingInformation, setPendingInformation] = useState<SubscriptionPendingInformation | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [uploading, setUploading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	function clearError() {
		setError(undefined);
	}

	async function handleAPICall(endpoint: string, body?: unknown) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		setLoading(true);

		try {
			const result = await api.put(endpoint, { body });
			setError(undefined);
			setLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function activate(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/activate`);
	}

	async function terminate(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/terminate`);
	}

	async function toggleHold(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/hold/toggle`);
	}

	async function renew(subscriptionId: string, period: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/payment/renew`, { period });
	}

	async function exportTemplate() {
		if (!tenant) {
			setLoading(false);
			return;
		}

		try {
			setLoading(true);
			const response = await api.get('/service/subscriptions/export-template', {
				headers: {
					Accept: 'text/csv',
				},
			});
			const blob = new Blob([response], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `subscription_template_${new Date().toISOString()}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function importSubscriptions(file: File) {
		if (!tenant) {
			setUploading(false);
			return;
		}

		const formData = new FormData();
		formData.append('file', file);

		try {
			setUploading(true);
			console.log({ formData, file });
			await api.post('/service/subscriptions/import', {
				body: formData,
			});
			setError(undefined);
			fetchPendingInformation(pendingInformationLimit);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setUploading(false);
		}
	}

	async function fetchPendingInformation(limit: number) {
		if (!tenant) {
			setLoading(false);
			setPendingInformation(undefined);
			return;
		}

		try {
			setLoading(true);
			const result = await api.get(`/service/subscriptions/pending-information/${limit}`);
			setPendingInformation(result);
			setError(undefined);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		mustFetchPendingInformation && fetchPendingInformation(pendingInformationLimit);
	}, [tenant, mustFetchPendingInformation, pendingInformationLimit]);

	return {
		pendingInformation,
		error,
		clearError,
		loading,
		uploading,
		activate,
		terminate,
		toggleHold,
		renew,
		exportTemplate,
		importSubscriptions,
	};
}
