/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconType } from '@iconscout/react-unicons';
import React, { MouseEventHandler } from 'react';
import UilIcon from './UilIcon';

export const Variant = {
	primary: 'primary',
	secondary: 'secondary',
	success: 'success',
	info: 'info',
	warning: 'warning',
	danger: 'danger',
	dark: 'dark',
	gray: 'gray',
	light: 'light',
	extraLight: 'extraLight',
	link: 'link',
} as const;

export type Variant = (typeof Variant)[keyof typeof Variant];

export const Style = {
	basic: 'basic',
	outline: 'outline',
	light: 'light',
	rounded: 'rounded',
	roundedOutline: 'roundedOutline',
} as const;

export type Style = (typeof Style)[keyof typeof Style];

export const Size = {
	xs: 'xs',
	sm: 'sm',
	md: 'md',
	lg: 'lg',
} as const;

export type Size = (typeof Size)[keyof typeof Size];

const bgBasic: { [key: string]: string } = {
	primary: 'bg-primary',
	secondary: 'bg-secondary',
	success: 'bg-success',
	info: 'bg-info',
	warning: 'bg-warning',
	danger: 'bg-danger',
	dark: 'bg-dark',
	gray: 'bg-gray-600',
	light: 'bg-light',
	extraLight: 'bg-light-extra',
};

const textOutline: { [key: string]: string } = {
	primary: 'text-primary',
	secondary: 'text-secondary',
	success: 'text-success',
	info: 'text-info',
	warning: 'text-warning',
	danger: 'text-danger',
	dark: 'text-dark',
	gray: 'text-gray-600',
	light: 'text-light',
	extraLight: 'text-light-extra',
};

const disabledClasses = 'opacity-50 cursor-not-allowed';

const styles: { [key: string]: (variant: Variant) => string } = {
	basic: (variant: Variant) => `${bgBasic[variant]} text-white rounded-md`,
	outline: (variant: Variant) => `bg-transparent border-[1px] hover:text-white ${textOutline[variant]} rounded-md`,
	light: (variant: Variant) =>
		`${bgBasic[variant]} bg-opacity-10 hover:text-white ${textOutline[variant]} rounded-md`,
	rounded: (variant: Variant) => `${bgBasic[variant]} text-white rounded-full`,
	roundedOutline: (variant: Variant) =>
		`bg-transparent border-[1px] hover:text-white ${textOutline[variant]} rounded-full`,
};

const classes: { [key: string]: (style: Style, disabled: boolean) => string } = {
	primary: (style: Style, disabled: boolean) =>
		`border-primary ${styles[style]('primary')} ${disabled ? disabledClasses : ''} hover:bg-primary-hbr`,
	secondary: (style: Style, disabled: boolean) =>
		`border-secondary ${styles[style]('secondary')} ${disabled ? disabledClasses : ''} hover:bg-secondary-hbr`,
	success: (style: Style, disabled: boolean) =>
		`border-success ${styles[style]('success')} ${disabled ? disabledClasses : ''} hover:bg-success-hbr`,
	info: (style: Style, disabled: boolean) =>
		`border-info ${styles[style]('info')} ${disabled ? disabledClasses : ''} hover:bg-info-hbr`,
	warning: (style: Style, disabled: boolean) =>
		`border-warning ${styles[style]('warning')} ${disabled ? disabledClasses : ''} hover:bg-warning-hbr`,
	danger: (style: Style, disabled: boolean) =>
		`border-danger ${styles[style]('danger')} ${disabled ? disabledClasses : ''} hover:bg-danger-hbr`,
	dark: (style: Style, disabled: boolean) =>
		`border-dark ${styles[style]('dark')} ${disabled ? disabledClasses : ''} hover:bg-dark-hbr`,
	gray: (style: Style, disabled: boolean) =>
		`border-gray-600 ${styles[style]('gray')} ${disabled ? disabledClasses : ''} hover:bg-gray-500`,
	light: (style: Style, disabled: boolean) =>
		`border-light ${styles[style]('light')} ${disabled ? disabledClasses : ''} hover:bg-light-hbr`,
	extraLight: (style: Style, disabled: boolean) =>
		`border-light-extra ${styles[style]('extraLight')} ${disabled ? disabledClasses : ''} hover:bg-light-hbr`,
	link: (style: Style, disabled: boolean) => `text-primary underline ${disabled ? disabledClasses : ''}`,
};

const sizes: { [key: string]: string } = {
	xs: 'h-8 px-5',
	sm: 'h-9 px-5',
	md: 'h-11 px-5',
	lg: 'h-12 px-7',
	link: 'h-auto',
};

export interface ButtonIcon {
	type: IconType;
	spin?: boolean;
}

export interface ButtonProps {
	type?: 'submit' | 'reset' | 'button';
	text?: string;
	submit?: boolean;
	variant?: Variant;
	onClick?: MouseEventHandler | undefined;
	style?: Style;
	size?: Size;
	icon?: IconType | ButtonIcon;
	block?: boolean;
	disabled?: boolean;
}

type IconProp = IconType | ButtonIcon;

function isButtonIcon(icon: IconProp): icon is ButtonIcon {
	return (icon as ButtonIcon).type !== undefined;
}

export default function Button({
	type = 'button',
	text,
	submit,
	variant = 'primary',
	size = 'md',
	style = 'basic',
	icon,
	onClick,
	block,
	disabled,
}: ButtonProps) {
	const variantClasses =
		variant !== 'link' ? classes[variant](style, !!disabled) : classes['link'](style, !!disabled);
	const sizeClasses = variant === 'link' ? sizes['link'] : sizes[size];
	const blockClass = block ? 'w-full' : '';

	return (
		<button
			className={`duration-200 ${variantClasses} ${sizeClasses} ${blockClass} text-sm font-bold inline-flex items-center justify-center gap-[6px]`}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{icon &&
				(isButtonIcon(icon) ? (
					<UilIcon type={icon.type} className={`w-4 h-4 ${icon.spin ? 'animate-spin' : ''}`} />
				) : (
					<UilIcon type={icon} className="w-4 h-4" />
				))}
			{text && <span>{text}</span>}
		</button>
	);
}
