import React, { ReactNode } from 'react';
import { UilCheckCircle, UilExclamationTriangle, UilTimesCircle, UilInfoCircle, Icon } from '@iconscout/react-unicons';
import { TailwindTextColorClass } from '../shared/tailwind';

export interface MessageBoxProps {
	title: string;
	type?: 'success' | 'warning' | 'danger' | 'info';
	children: ReactNode;
}

export default function MessageBox({ title, type, children }: MessageBoxProps) {
	let UilIcon: Icon | undefined = undefined;
	let color: TailwindTextColorClass | undefined = undefined;

	switch (type) {
		case 'warning':
			UilIcon = UilExclamationTriangle;
			color = 'text-warning';
			break;
		case 'danger':
			UilIcon = UilTimesCircle;
			color = 'text-danger';
			break;
		case 'info':
			UilIcon = UilInfoCircle;
			color = 'text-info';
			break;
		case 'success':
			UilIcon = UilCheckCircle;
			color = 'text-success';
			break;
	}

	return (
		<div className="gap-6">
			<div className="flex items-center justify-center gap-2 p-4 w-full border-b-[1px] shadow-md">
				{UilIcon && <UilIcon className={color} size="24" />}
				<h2 className={`font-bold text-lg ${color}`}>{title}</h2>
			</div>
			<div className="mx-8 my-4 flex flex-col items-center gap-4">{children}</div>
		</div>
	);
}
