import React, { useEffect } from 'react';

import { CRUDFormGroupType } from '../../components/CRUDFormGroup';
import { BeforeSubmitType, useCRUDFormPage } from '../../hooks/useCRUDForm';
import { NAS, NASPool } from '../../shared/models/infra/NAS';
import { CRUDFormGroups } from '../../components/CRUDFormGroups';
import GenericCRUDFormPage from '../GenericCRUDFormPage';
import { generateRandomId } from '../../shared/utils';

const NEW_POOL = 'new_pool';

const groups: Array<CRUDFormGroupType> = [
	{
		key: 'info-data',
		title: 'Info',
		rows: [
			{
				key: 'row-1',
				fields: [
					{ property: 'name', label: 'Name', highlight: true, proportion: 'w-1/3' },
					{
						property: 'ip',
						label: 'Router RADIUS Src. Address',
						highlight: true,
						proportion: 'w-1/3',
					},
					{
						property: 'identifier',
						label: 'Router RADIUS Identity',
						highlight: true,
						proportion: 'w-1/3',
					},
				],
			},
		],
	},
	{
		key: 'maintenance-data',
		title: 'Maintenance',
		rows: [
			{
				key: 'row-1',
				fields: [
					{ property: 'api_url', label: 'API (Router Public IP)', proportion: 'w-1/3' },
					{ property: 'username', label: 'Username', proportion: 'w-1/3' },
					{
						property: 'secret',
						label: 'Secret',
						proportion: 'w-1/3',
						type: 'password',
						placeholder: 'Type to change',
					},
				],
			},
		],
	},
	// // Commenting this block to hide the fields that are useful at this version.
	// {
	// 	key: 'connectivity-data',
	// 	title: 'Connectivity',
	// 	rows: [
	// 		{
	// 			key: 'row-1',
	// 			fields: [
	// 				{ property: 'smtp_community', label: 'SMTP', proportion: 'w-1/4' },
	// 				{ property: 'ssh_port', label: 'SSH Port', proportion: 'w-1/4' },
	// 				{ property: 'ftp_port', label: 'FTP Port', proportion: 'w-1/4' },
	// 				{ property: 'max_mb', label: 'Max. Mb', proportion: 'w-1/4' },
	// 				{ property: 'max_connections', label: 'Max. Connections', proportion: 'w-1/4' },
	// 			],
	// 		},
	// 		{
	// 			key: 'row-2',
	// 			fields: [
	// 				{
	// 					property: 'ssh_active',
	// 					label: 'SSH Enabled',
	// 					highlight: true,
	// 					proportion: 'w-1/4',
	// 					type: 'checkbox',
	// 				},
	// 			],
	// 		},
	// 		{
	// 			key: 'row-3',
	// 			fields: [
	// 				{
	// 					property: 'ftp_active',
	// 					label: 'FTP Enabled',
	// 					highlight: true,
	// 					proportion: 'w-1/4',
	// 					type: 'checkbox',
	// 				},
	// 			],
	// 		},
	// 	],
	// },
];

function handleBeforeSubmit(payload: BeforeSubmitType, form: NAS): BeforeSubmitType {
	payload.ftp_active = typeof form.ftp_active === 'string' ? form.ftp_active === 'true' : false;
	payload.ssh_active = typeof form.ssh_active === 'string' ? form.ssh_active === 'true' : false;

	payload.max_mb = typeof form.max_mb === 'string' ? parseInt(form.max_mb) : form.max_mb;
	payload.max_connections =
		typeof form.max_connections === 'string' ? parseInt(form.max_connections) : form.max_connections;
	payload.ssh_port = typeof form.ssh_port === 'string' ? parseInt(form.ssh_port) : form.ssh_port;
	payload.ftp_port = typeof form.ftp_port === 'string' ? parseInt(form.ftp_port) : form.ftp_port;

	const pools = [];
	for (const pool of form.infra_nas_pool) {
		if (!pool.name) continue;
		pools.push({
			id: pool.id.indexOf(NEW_POOL) === -1 ? pool.id : undefined,
			name: pool.name,
		} as NASPool);
	}
	payload.pools = pools;

	return payload;
}

const endpoint = '/infra/nas';
const backwardUrl = '/infra/nas';
const createUrl = '/infra/nas/new';

function NASByIdPage() {
	const { form, setForm, handleSave, loading, error } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		undefined,
		handleBeforeSubmit,
	);

	useEffect(() => {
		const nas = form as NAS;
		if (nas.infra_nas_pool && nas.infra_nas_pool.length > 0) return;
		handleAddPool();
	}, [form]);

	function handleAddPool() {
		const nas = form as NAS;
		const pool: NASPool = {
			id: `${NEW_POOL}_${generateRandomId(12)}`,
			name: '',
		};

		if (!nas.infra_nas_pool) {
			nas.infra_nas_pool = [];
		}

		nas.infra_nas_pool.push(pool);

		setForm({ ...nas });
	}

	function handleRemovePool(id: string) {
		return () => {
			const nas = form as NAS;
			if (!nas?.infra_nas_pool) return [];

			nas.infra_nas_pool = nas.infra_nas_pool.filter((pool) => pool.id !== id);

			setForm({ ...nas });
		};
	}

	function handleValueExtractor(pool: NASPool) {
		return (form: NAS) => {
			return (form as NAS).infra_nas_pool.find((item) => item.id === pool.id)?.name || '';
		};
	}

	function handleValueChange(pool: NASPool) {
		return (value: string) => {
			pool.name = value;
			setForm({ ...form });
		};
	}

	const poolsGroup: CRUDFormGroupType = {
		key: 'pools-data',
		title: 'Pools',
		action: {
			icon: 'UilPlus',
			action: handleAddPool,
		},
		rows: [],
	};
	const nas = form as NAS;
	if (nas && nas.infra_nas_pool && nas.infra_nas_pool.length > 0) {
		for (const pool of nas.infra_nas_pool) {
			poolsGroup.rows.push({
				key: pool.id,
				fields: [
					{
						property: `pools[${pool.id}]`,
						valueExtractor: handleValueExtractor(pool),
						onChange: handleValueChange(pool),
						proportion: 'w-full',
						actionIcon: {
							type: 'UilTrashAlt',
							color: 'text-light',
							action: handleRemovePool(pool.id),
						},
					},
				],
			});
		}
	}

	return (
		<GenericCRUDFormPage
			title="NAS"
			groups={groups}
			backwardUrl={backwardUrl}
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={loading}
			handleSave={handleSave}
			error={error}
		>
			<CRUDFormGroups form={form as never} groups={[poolsGroup]} setForm={setForm} loading={loading} />
		</GenericCRUDFormPage>
	);
}

export default NASByIdPage;
