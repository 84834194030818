import React from 'react';
import CRUDFormField, { CRUDFormFieldType } from './CRUDFormField';
import FormGroup from '../slices/FormGroup';
import { IconType } from '@iconscout/react-unicons';
import CRUDFormSelect, { CRUDFormSelectType } from './CRUDFormSelect';

export interface CRUDFormGroupType {
	key: string;
	title?: string;
	rows: Array<CRUDFormGroupRow>;
	action?: {
		icon: IconType;
		action: () => void;
	};
}

export interface CRUDFormGroupRow {
	key: string;
	fields: Array<CRUDFormFieldType | CRUDFormSelectType>;
}

export interface CRUDFormGroupProps {
	group: CRUDFormGroupType;
	form: never;
	setForm: (form: object) => void;
}

function CRUDFormGroup({ group, form, setForm }: CRUDFormGroupProps) {
	return (
		<FormGroup key={group.key} title={group.title} action={group.action}>
			{group.rows.map((row) => (
				<div key={row.key} className="mb-4 flex gap-2">
					{row.fields.map((field) =>
						(field as CRUDFormSelectType).options !== undefined ? (
							<CRUDFormSelect
								key={field.property}
								field={field as CRUDFormSelectType}
								form={form}
								setForm={setForm}
							/>
						) : (
							<CRUDFormField key={field.property} field={field} form={form} setForm={setForm} />
						),
					)}
				</div>
			))}
		</FormGroup>
	);
}

export default CRUDFormGroup;
