import React, { useEffect, useState } from 'react';
import Button, { ButtonIcon, Variant } from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { IconType } from '@iconscout/react-unicons';
import StickyBottom from '../components/StickyBottom';

export type BottomControlSaveEvent = 'save' | 'save/back' | 'save/create';

export interface BottomControlCustomAction {
	title: string;
	variant: Variant;
	icon?: {
		type: IconType;
		spin?: boolean;
	};
	action: () => void;
	disabled?: boolean;
}

export interface BottomControlProps {
	backwardUrl: string;
	createUrl: string;
	onSave: (event: BottomControlSaveEvent) => Promise<boolean>;
	customActions?: Array<BottomControlCustomAction>;
	minimalActions?: boolean;
	loading?: boolean;
}

const SPIN_ICON: ButtonIcon = { type: 'UilSpinner', spin: true };

export default function BottomControl({
	onSave,
	backwardUrl,
	createUrl,
	customActions,
	minimalActions,
	loading,
}: BottomControlProps) {
	const navigate = useNavigate();
	const [clickedButton, setClickedButton] = useState<string>();

	useEffect(() => {
		if (!loading && clickedButton) setClickedButton(undefined);
	}, [loading]);

	function handleSave() {
		setClickedButton('save');
		onSave('save');
	}

	async function handleSaveCreate() {
		setClickedButton('save/create');
		const result = await onSave('save/create');
		if (result) navigate(createUrl);
	}

	async function handleSaveBack() {
		setClickedButton('save/back');
		const result = await onSave('save/back');
		if (result) navigate(backwardUrl);
	}

	function handleBack() {
		navigate(backwardUrl);
	}

	function resolveLoading(button: string) {
		if (!loading) return;
		if (button !== clickedButton) return;

		return SPIN_ICON;
	}

	return (
		<StickyBottom>
			<div className="h-24 flex items-center gap-2 p-4">
				<Button text="Save" onClick={handleSave} icon={resolveLoading('save')} />
				{!minimalActions && (
					<>
						<Button
							text="Save & Start New"
							variant="primary"
							style="outline"
							onClick={handleSaveCreate}
							icon={resolveLoading('save/create')}
						/>
						<Button
							text="Save & Back"
							variant="primary"
							style="outline"
							onClick={handleSaveBack}
							icon={resolveLoading('save/back')}
						/>
					</>
				)}
				{customActions &&
					customActions.map((customAction) => (
						<Button
							key={customAction.title.replaceAll(' ', '')}
							text={customAction.title}
							variant={customAction.variant}
							icon={customAction.icon}
							disabled={customAction.disabled}
							onClick={customAction.action}
							style="roundedOutline"
						/>
					))}
				<div className="ml-auto">
					<Button text="Voltar" variant="link" onClick={handleBack} />
				</div>
			</div>
		</StickyBottom>
	);
}
