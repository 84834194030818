import React, { createContext, useState, ReactNode, useEffect, useCallback } from 'react';

import { useAuth } from '../hooks/useAuth';
import useAPITenants from '../hooks/api/useAPITenants';
import useAPIProfile from '../hooks/api/useAPIProfile';
import { RoleType, SessionUser } from '../shared/models/User';
import { SubscriptionStatus } from '../shared/models/saas/account/SubscriptionResponse';
import AlertBox from '../slices/AlertBox';
import Overlay from '../components/Overlay';
import { UilSpinner } from '@iconscout/react-unicons';

const TENANT_ROLE_ID_LS_KEY = 'tenantcontext_state_tenant-role-id';

export type ProfileProviderProps = {
	children: ReactNode;
};

export interface TenantSubscription {
	status?: SubscriptionStatus;
}

export interface TenantRole {
	id: string;
	name: string;
	role: RoleType;
	subscription?: TenantSubscription;
}

export type ProfileContextType = {
	tenant?: TenantRole;
	saasTenant?: TenantRole;
	setTenant: (id: string) => void;
	tenants: TenantRole[];
	sessionUser?: SessionUser;
	currentIsSaas: boolean;
	loading: boolean;
};

const COUNTDOWN = 5;

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
	const [selectedTenantRoleId, setSelectedTenantRoleId] = useState<string | undefined>(undefined);
	const [tenantRoles, setTenantRoles] = useState<TenantRole[]>([]);

	const { isAuthenticated, user } = useAuth();
	const { tenants, loading: tenantsLoading, error: tenantsError } = useAPITenants();
	const { sessionUser, loading: profileLoading, error: profileError } = useAPIProfile();

	const [countdown, setCountdown] = useState(COUNTDOWN);

	const loading = tenantsLoading || profileLoading;

	useEffect(() => {
		const storedTenantRoleId = localStorage.getItem(TENANT_ROLE_ID_LS_KEY);
		if (!storedTenantRoleId) return;
		setSelectedTenantRoleId(storedTenantRoleId);
	}, []);

	useEffect(() => {
		if (selectedTenantRoleId) localStorage.setItem(TENANT_ROLE_ID_LS_KEY, selectedTenantRoleId);
	}, [selectedTenantRoleId]);

	const loadTenantRoles = useCallback(() => {
		if (!isAuthenticated) return;
		if (!tenants) return;
		if (!sessionUser) return;

		const customClaimRoles = sessionUser.roles;
		const tenantRoles = Object.keys(customClaimRoles).map((key) => {
			const tenant = tenants ? tenants.find((tenant) => tenant.id === key) : null;
			const tenantName = tenant?.name || key;
			const tenantRole: TenantRole = {
				id: key,
				name: tenantName || (loading ? 'Loading tenant...' : key),
				role: customClaimRoles[key as RoleType].name,
			};

			if (tenant?.subscription) {
				tenantRole.subscription = {
					status: tenant.subscription.status,
				};
			}

			return tenantRole;
		});

		setTenantRoles(tenantRoles);
		if (!selectedTenantRoleId) setSelectedTenantRoleId(tenantRoles[0].id);
	}, [isAuthenticated, tenants, sessionUser, loading]);

	useEffect(() => {
		if (!isAuthenticated || !user) return;
		loadTenantRoles();
	}, [isAuthenticated, user, loadTenantRoles]);

	useEffect(() => {
		if (!tenantsError && !profileError) return;

		// Set an interval that updates the countdown every second
		const interval = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		// Clear the interval after 5 seconds
		const timeout = setTimeout(() => {
			clearInterval(interval);
			setCountdown(0); // Ensure the countdown is set to 0
		}, COUNTDOWN * 1000);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [tenantsError, profileError]);

	const tenant = tenantRoles.find((tenant) => tenant.id === selectedTenantRoleId);
	const saasTenant = tenantRoles.find((tenant) => tenant.role === 'SAASAdmin' || tenant.role === 'SAASManager');
	const currentIsSaas = tenant === saasTenant;

	return (
		<ProfileContext.Provider
			value={{
				tenant,
				saasTenant,
				setTenant: setSelectedTenantRoleId,
				tenants: tenantRoles,
				sessionUser,
				currentIsSaas,
				loading,
			}}
		>
			{children}
			{(tenantsError || profileError) && (
				<AlertBox
					type="danger"
					title="Maintenance Alert"
					message="Gravity services may be temporarily unavailable due to ongoing maintenance. We apologize for any inconvenience."
					ok={countdown !== 0 ? `Refresh in ${countdown}s` : 'Refresh'}
					onOk={() => window.location.reload()}
					okDisabled={countdown !== 0}
				/>
			)}
			{loading && (
				<Overlay transparent>
					<UilSpinner className="text-primary-900 animate-spin" size={48} />
				</Overlay>
			)}
		</ProfileContext.Provider>
	);
};
