import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { IconType, IconProps } from '@iconscout/react-unicons';

type UilIconProps = {
	type: IconType;
} & IconProps;

function UilIcon(props: UilIconProps) {
	const Icon = Unicons[props.type];
	return <Icon {...props} />;
}

export default UilIcon;
