/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from 'react';
import UilIcon from './UilIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface SelectProps {
	options: Array<{ key: string; label: string }>;
	label?: string;
	value?: string;
	placeholder?: string;
	inputRef?: React.LegacyRef<HTMLInputElement> | React.LegacyRef<HTMLTextAreaElement>;
	onChange?: (key: string) => void;
	highlight?: boolean;
	condensed?: boolean;
	rounded?: boolean;
	disposition?: 'row' | 'column';
	disabled?: boolean;
	full?: boolean;
}

export default function Select({
	options,
	label,
	value,
	placeholder,
	inputRef,
	onChange,
	highlight,
	condensed,
	rounded,
	disposition,
	disabled,
	full,
}: SelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSelect = (key: string) => {
		setIsOpen(false);
		onChange && onChange(key);
	};

	const condensedClasses = condensed ? 'h-10 text-sm' : 'py-2 h-12';
	const roundedClasses = rounded ? 'rounded-full' : 'rounded-md';
	const disabledClasses = disabled ? 'cursor-not-allowed  text-light-extra' : '';

	return (
		<div
			className={`relative flex ${disposition === 'row' ? 'flex-row items-center gap-2' : 'flex-col'} ${
				full && 'w-full'
			}`}
		>
			{label && (
				<label htmlFor="input">
					<span className={`${highlight && 'font-bold'} text-sm`}>{label}</span>
				</label>
			)}
			<div className="relative flex flex-1 md:flex-row" ref={dropdownRef}>
				<button
					onClick={() => setIsOpen(!isOpen)}
					className={`flex justify-between items-center border-gray-300 text-sm border-[1px] ${condensedClasses} ${roundedClasses} ${disabledClasses} w-full bg-white outline-none focus:border-primary-600 text-left px-4`}
					disabled={disabled}
				>
					<span className={value ? '' : 'text-light'}>
						{value
							? options.find((option) => option.key === value)?.label
							: placeholder || 'Select an option'}
					</span>
					<FontAwesomeIcon icon={faChevronDown} className="text-xs text-gray-400" />
				</button>
				{isOpen && (
					<div className="absolute z-50 w-full bg-white border border-gray-300 rounded-md shadow-lg">
						{options.map((option) => (
							<button
								key={option.key}
								onClick={() => handleSelect(option.key)}
								className="block w-full text-left text-sm px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
							>
								{option.label}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
}
