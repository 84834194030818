import React from 'react';
import { useEffect, useState } from 'react';

import WrapperPage from '../WrapperPage';
import FormGroup from '../../slices/FormGroup';
import useCRUDQuery from '../../hooks/api/useCRUDQuery';
import {
	NAS,
	NASConfigurationResponse,
	NASConnectionResponse,
	NASFirewallFilterResponse,
	NASLastSyncResponse,
	NASPPPoEServerResponse,
	NASRadiusResponse,
} from '../../shared/models/infra/NAS';
import useAPINASRouterOS from '../../hooks/api/useAPINASRouterOS';
import {
	UilCheckSquare,
	UilClock,
	UilCloudBlock,
	UilInfoCircle,
	UilLinkBroken,
	UilNoEntry,
	UilSpinner,
} from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Overlay from '../../components/Overlay';
import { timeSince } from '../../shared/utils';
import DataTable from '../../components/DataTable';
import MessageBox from '../../slices/MessageBox';

function NASConfigurationPage() {
	const [nasIds, setNASIds] = useState<Array<string> | null>(null);
	const [connectionChecked, setConnectionChecked] = useState(false);

	const { response: nas } = useCRUDQuery<NAS>('/infra/nas', { initialPageSize: 100 });
	const {
		connectionStatus,
		loadingConnectionStatus,
		configuration,
		sync,
		loadingConfigurationCheck,
		loadingSyncCheck,
		error,
		clearError,
		applyConfiguration,
		actionLoading,
	} = useAPINASRouterOS(nasIds, {
		mustFetchConnectionStatus: !!nasIds && !connectionChecked,
		mustFetchConfiguration: connectionChecked,
		mustFetchSync: !!nasIds,
	});

	useEffect(() => {
		if (!nas) return;

		if (!nasIds) {
			const nasIds = nas.rows.map((nas) => nas.id) || null;
			setNASIds(nasIds);
			return;
		}

		if (connectionStatus) {
			const nasIds = connectionStatus
				.filter((connection) => connection.connection.state)
				.map((connection) => connection.nas_id);
			setNASIds(nasIds);
			setConnectionChecked(true);
			return;
		}
	}, [nas, connectionStatus]);

	return (
		<WrapperPage title="NAS Control Panel" error={error} onAlertClose={clearError}>
			<div className="m-4 flex flex-wrap gap-4">
				{nas?.rows.map((nas) => (
					<NASConfiguration
						key={nas.id}
						nas={nas}
						connection={connectionStatus?.find((connection) => connection.nas_id === nas.id)}
						configuration={configuration?.find((configuration) => configuration.nas_id === nas.id)}
						sync={sync?.find((configuration) => configuration.nas_id === nas.id)}
						loadingConnectionStatus={loadingConnectionStatus}
						loadingConfigurationCheck={loadingConfigurationCheck}
						loadingSyncCheck={loadingSyncCheck}
						applyConfiguration={applyConfiguration}
						actionLoading={actionLoading}
					/>
				))}
			</div>
		</WrapperPage>
	);
}

function NASHeaderAction({ nas, connection }: { nas: NAS; connection?: NASConnectionResponse }) {
	return (
		<div className="flex flex-col items-end">
			<span className="text-sm text-light">{nas.api_url}</span>
			<span className="text-sm font-bold text-light">{connection?.connection.version}</span>
		</div>
	);
}

function NASConfiguration({
	nas,
	connection,
	configuration,
	sync,
	loadingConfigurationCheck,
	loadingConnectionStatus,
	loadingSyncCheck,
	applyConfiguration,
	actionLoading,
}: {
	nas: NAS;
	connection?: NASConnectionResponse;
	configuration?: NASConfigurationResponse;
	sync?: NASLastSyncResponse;
	loadingConnectionStatus: boolean;
	loadingConfigurationCheck: boolean;
	loadingSyncCheck: boolean;
	applyConfiguration: (nas_id: string) => Promise<boolean>;
	actionLoading: boolean;
}) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	async function handleApplyConfiguration() {
		await applyConfiguration(nas.id);
		setOpen(false);
	}

	return (
		<div className="w-full md:w-96 flex">
			<FormGroup
				title={nas.name}
				titleAction={{ icon: 'UilEditAlt', action: () => navigate(`/infra/nas/${nas.id}`) }}
				action={<NASHeaderAction nas={nas} connection={connection} />}
				grow
			>
				<div className="h-full flex flex-col gap-6 justify-between">
					<ul className="flex flex-col gap-2">
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASConnection connection={connection} loading={loadingConnectionStatus} />
						</li>
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASRadius
								connection={connection}
								radius={configuration?.radius}
								loading={loadingConfigurationCheck}
							/>
						</li>
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASPPPoEServers
								connection={connection}
								pppoe_server={configuration?.pppoe_server}
								loading={loadingConfigurationCheck}
							/>
						</li>
						<li className="pb-2 border-b border-gray-200 last:border-none">
							<NASFirewallFilter
								connection={connection}
								firewall_filter={configuration?.firewall_filter}
								loading={loadingConfigurationCheck}
							/>
						</li>
						<li className="pb-2">
							<NASLastSync lastSync={sync} loading={loadingSyncCheck} />
						</li>
					</ul>
					{!loadingConnectionStatus &&
						!loadingConfigurationCheck &&
						connection?.connection.state &&
						!configuration?.valid && (
							<Button
								text="Fix Configurations"
								style="roundedOutline"
								variant="warning"
								icon="UilLinkBroken"
								onClick={() => setOpen(true)}
							/>
						)}
				</div>
			</FormGroup>
			{open && (
				<Overlay>
					<MessageBox title={nas.name}>
						<span>The following configurations will be fixed.</span>
						<textarea className="w-full font-mono text-xs border-[1px] p-2 resize-none" disabled>
							{[
								configuration?.radius.status !== 'ok' && '- RADIUS',
								configuration?.firewall_filter.status !== 'ok' && '- NAS Firewall',
								configuration?.firewall_filter.status !== 'ok' &&
									'  • Firewall Filter (foward, drop, gravity-list)',
								configuration?.firewall_filter.status !== 'ok' && '  • Interface List',
							]
								.filter((item) => item)
								.join('\n')}
						</textarea>
						<div className="mt-4 flex gap-2">
							<div className="w-32">
								<Button
									block
									text="Fix Now"
									icon={actionLoading ? { type: 'UilSpinner', spin: true } : 'UilLinkBroken'}
									variant="warning"
									onClick={handleApplyConfiguration}
									disabled={actionLoading}
								/>
							</div>
							<div className="w-32">
								<Button
									block
									text="Close"
									style="outline"
									variant="light"
									onClick={() => setOpen(false)}
								/>
							</div>
						</div>
					</MessageBox>
				</Overlay>
			)}
		</div>
	);
}

function NASConnection({ connection, loading }: { connection?: NASConnectionResponse; loading: boolean }) {
	const isLoading = loading && !connection;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && connection?.connection.state && <UilCheckSquare className="text-success" />}
				{!isLoading && !connection?.connection.state && <UilNoEntry className="text-warning" />}
			</div>
			<div>
				<span className="font-bold">Connection:</span>
				<div className="text-light">
					{isLoading && <span>Connecting...</span>}
					{!isLoading && connection?.connection.state && <span>Connected</span>}
					{!isLoading && !connection?.connection.state && <span>{connection?.connection.msg}</span>}
				</div>
			</div>
		</div>
	);
}

function NASRadius({
	connection,
	radius,
	loading,
}: {
	connection?: NASConnectionResponse;
	radius?: NASRadiusResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !radius;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && radius?.status === 'ok' && <UilCheckSquare className="text-success" />}
				{!isLoading && connected && radius?.status !== 'ok' && <UilLinkBroken className="text-warning" />}
				{!waitingConnection && !connected && <UilCloudBlock className="text-light-extra" />}
				{waitingConnection && <UilClock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">RADIUS:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && radius?.status === 'ok' && <span>{radius.message}</span>}
					{!isLoading && radius && radius?.status !== 'ok' && (
						<ul className="list-disc ml-4">
							{radius.issues?.map((issue) => <li key={issue}>{issue}</li>)}
						</ul>
					)}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASPPPoEServers({
	connection,
	pppoe_server,
	loading,
}: {
	connection?: NASConnectionResponse;
	pppoe_server?: NASPPPoEServerResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !pppoe_server;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && pppoe_server?.status === 'ok' && <UilCheckSquare className="text-success" />}
				{!isLoading && connected && pppoe_server?.status !== 'ok' && <UilLinkBroken className="text-warning" />}
				{!waitingConnection && !connected && <UilCloudBlock className="text-light-extra" />}
				{waitingConnection && <UilClock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">PPPoE Servers:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && pppoe_server?.status === 'ok' && (
						<ul className="flex flex-col">
							{pppoe_server.servers
								?.filter((server) => !server.disabled)
								.map((server) => (
									<span
										key={server.service_name}
									>{`${server.service_name}:${server.interface}:profile(${server.default_profile})`}</span>
								))}
						</ul>
					)}
					{!isLoading && pppoe_server && pppoe_server?.status !== 'ok' && <span>{pppoe_server.message}</span>}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASFirewallFilter({
	connection,
	firewall_filter,
	loading,
}: {
	connection?: NASConnectionResponse;
	firewall_filter?: NASFirewallFilterResponse;
	loading: boolean;
}) {
	const waitingConnection = !connection;
	const connected = !waitingConnection ? connection.connection.state : false;
	const isLoading = connected && loading && !firewall_filter;
	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				{isLoading && <UilSpinner className="text-light-gray animate-spin" />}
				{!isLoading && firewall_filter?.status === 'ok' && <UilCheckSquare className="text-success" />}
				{!isLoading && connected && firewall_filter?.status !== 'ok' && (
					<UilLinkBroken className="text-warning" />
				)}
				{!waitingConnection && !connected && <UilCloudBlock className="text-light-extra" />}
				{waitingConnection && <UilClock className="text-light-extra" />}
			</div>
			<div>
				<span className="font-bold">NAS Firewall:</span>
				<div className="text-light">
					{isLoading && <span>Loading configurations...</span>}
					{!isLoading && firewall_filter && <span>{firewall_filter.message}</span>}
					{waitingConnection && <span>Waiting for connection...</span>}
					{!waitingConnection && !connected && <span>Not connected to check!</span>}
				</div>
			</div>
		</div>
	);
}

function NASLastSync({ lastSync, loading }: { lastSync?: NASLastSyncResponse; loading: boolean }) {
	const [open, setOpen] = useState(false);
	const isLoading = loading && !lastSync;

	let status: 'success' | 'failed' | 'loading' | 'never' = 'loading';
	if (!isLoading) {
		if (lastSync && lastSync.status !== 'never') status = lastSync.status === 'ok' ? 'success' : 'failed';
		else status = 'never';
	} else if (isLoading) {
		status = 'loading';
	} else {
		status = 'never';
	}

	const colors = {
		success: 'text-success',
		failed: 'text-warning',
		loading: 'text-light-extra',
		never: 'text-info',
		waiting: 'text-light-extra',
	};
	const color = colors[status];

	return (
		<div className="text-sm flex flex-row items-center gap-2">
			<div>
				<UilInfoCircle className={`${color}`} />
			</div>
			<div>
				<span className="font-bold">Last Synchronization:</span>
				<div className="text-light">
					{['success', 'failed', 'never'].indexOf(status) > -1 && (
						<div className="flex">
							<span className={`${color} font-bold`}>
								{status === 'success' && <>Succeeded&nbsp;</>}
								{status === 'failed' && <>Failed&nbsp;</>}
							</span>
							<span>{(lastSync?.finished_at && timeSince(lastSync?.finished_at)) || 'Never'}</span>
							{status === 'failed' && (
								<>
									&nbsp;
									<Button text="(details)" variant="link" onClick={() => setOpen(true)} />
								</>
							)}
						</div>
					)}
					{status === 'loading' && <span>Loading last sync information...</span>}
				</div>
			</div>
			{open && lastSync && (
				<Overlay>
					<MessageBox title="Sync Failures">
						<div className="min-w-96 flex flex-col gap-2">
							{lastSync.subscription_failures && lastSync.subscription_failures.length > 0 && (
								<>
									<h3 className="font-bold text-sm">Subscriptions failures</h3>
									<div className="max-h-[30vh] max-w-[90vw] overflow-auto custom-scrollbar">
										<DataTable
											style="condensed"
											fields={[
												{
													title: 'Subscription ID',
													property: 'subscription_id',
													extractor: (row) => (
														<Button
															text={row.subscription_id}
															variant="link"
															onClick={() =>
																window.open(
																	`/subscriptions/${row.subscription_id}`,
																	'_blank',
																)
															}
														/>
													),
												},
												{
													title: 'Error Message',
													property: 'failure_message',
												},
											]}
											rows={lastSync.subscription_failures}
										/>
									</div>
								</>
							)}
							{lastSync.failure_message && (
								<>
									<h3 className="font-bold text-sm">General failure</h3>
									<textarea
										className="resize-none w-full p-2 max-h-24 bg-red-100 rounded-lg text-red-500"
										disabled
									>
										{lastSync.failure_message}
									</textarea>
								</>
							)}
						</div>
						<div className="flex gap-2">
							<Button text="Close" style="roundedOutline" size="xs" onClick={() => setOpen(false)} />
						</div>
					</MessageBox>
				</Overlay>
			)}
		</div>
	);
}

export default NASConfigurationPage;
