import React, { useEffect, useState } from 'react';
import { UilServerNetwork } from '@iconscout/react-unicons';

import Select from '../components/Select';
import useCRUDQuery from '../hooks/api/useCRUDQuery';
import StickyBottom from '../components/StickyBottom';
import Button from '../components/Button';
import DataTable, { DataTableField } from '../components/DataTable';
import { NAS, NASConnectionResponse } from '../shared/models/infra/NAS';
import WhiteBox from '../components/WhiteBox';
import useAPINASRouterOS from '../hooks/api/useAPINASRouterOS';
import useAPIISPImport, { ImportType } from '../hooks/api/useAPIISPImport';
import { Currency } from '../shared/models/currency';
import Logs, { LogLevels, summarizeLogs } from '../components/Logs';
import ConfimationBox from '../slices/ConfirmationBox';
// import { useNavigate } from 'react-router-dom';
import Input from '../components/Input';
import WrapperPage from './WrapperPage';
import AlertBox, { AlertBoxProps } from '../slices/AlertBox';
import Bullet from '../slices/Bullet';
import Selection from '../slices/Selection';
import { useProfile } from '../hooks/useProfile';

function Status({ status, loading }: { status?: NASConnectionResponse; loading: boolean }) {
	const classes = !status
		? `text-light-extra ${loading ? 'animate-pulse' : ''}`
		: status.connection.state
			? 'text-success'
			: 'text-danger';
	return (
		<div className="flex items-center gap-2">
			<UilServerNetwork className={classes} />
			{status && <span>{status.connection.msg || 'Connected'}</span>}
		</div>
	);
}

const endpointCurrency = '/currency';
const endpointNAS = '/infra/nas';

export default function ImportPage() {
	// const navigate = useNavigate();
	const { tenant: selectedISP } = useProfile();

	const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
	const { response: currencyResponse } = useCRUDQuery<Currency>(endpointCurrency, { asSaas: true });

	const [selectedNAS, setSelectedNAS] = useState<NAS[]>([]);
	const {
		loading: nasLoading,
		response: nasResponse,
		error: currencyError,
	} = useCRUDQuery<NAS>(endpointNAS, {
		emptyQueryFetch: false,
		initialSortField: 'name',
		initialSortOrder: 'asc',
	});

	const [alertBox, setAlertBox] = useState<AlertBoxProps>();
	const [confirmImport, setConfirmImport] = useState(false);
	const [fetchNAS, setFetchNAS] = useState<string[] | null>(null);
	const {
		loadingConnectionStatus,
		connectionStatus,
		refresh,
		clear,
		error: routerOSError,
	} = useAPINASRouterOS(fetchNAS, {
		asSaas: true,
		mustFetchConnectionStatus: !!fetchNAS,
	});

	const [nasWipe, setNASWipe] = useState<NAS | undefined>(undefined);
	const [confirmWipe, setConfirmWipe] = useState<boolean>(false);
	const {
		importLogs,
		fetchImport,
		importLoading,
		wipeLoading,
		fetchWipe,
		wipeResult,
		error: importError,
	} = useAPIISPImport();

	const [selectedTab, setSelectedTab] = useState<string>();
	const [importAlertMessage, setImportAlertMessage] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const error = importError || routerOSError || currencyError || undefined;

	function handleSelectedLevel(tab: string) {
		setSelectedTab(tab);
	}

	function levelsFromSelectedTab(tab: string | undefined): Array<LogLevels> | undefined {
		if (tab === 'warnings') return ['WARNING'];
		if (tab === 'errors') return ['ERROR'];
		return undefined;
	}

	function handleTestConnection() {
		if (!selectedISP && selectedNAS.length === 0) {
			setFetchNAS(null);
			return;
		}

		setFetchNAS(selectedNAS.map((nas) => nas.id));
		clear();
		refresh();
	}

	function handleConfirmImport() {
		setConfirmImport(true);
	}

	function handleImport(type: ImportType) {
		return async () => {
			if (!selectedISP) return;
			if (!connectionStatus) return;

			// Filter connectionStatus to include only selectedNAS
			const selectedConnectionStatus = connectionStatus.filter((cs) =>
				selectedNAS.some((nas) => nas.id === cs.nas_id),
			);

			if (selectedConnectionStatus.filter((cs) => !cs.connection.state).length > 0) {
				setImportAlertMessage('All the selected NAS must be accessible in order to run the import.');
				return;
			}

			if (!selectedCurrency) {
				setImportAlertMessage('Select a currency before importing!');
				return;
			}

			setConfirmImport(false);

			await fetchImport(selectedISP.id, {
				currency_id: selectedCurrency.id,
				nas: selectedNAS.map((nas) => nas.id),
				type,
			});

			setAlertBox({
				title: type === 'commitment' ? 'Import completed' : 'Import testing',
				message:
					type === 'commitment'
						? 'All the data were imported into Gravity successfully'
						: 'None of the data were imported into Gravity!\nTry now the Import button.',
				type: type === 'commitment' ? 'success' : 'info',
			});
		};
	}

	function handleSelectNAS(row: NAS) {
		return (value: string) => {
			if (!nasResponse) return;

			const nas = nasResponse.rows.find((nas) => nas.id === row.id);
			if (!nas) return;

			if (value === 'true') {
				setSelectedNAS([...selectedNAS, nas]);
			} else {
				setSelectedNAS(selectedNAS.filter((nas) => nas.id !== row.id));
			}
		};
	}

	// function handleImportFromTxt(nasId: string) {
	// 	if (!selectedISP) return;
	// 	if (!selectedCurrency) {
	// 		alert('Select a currency before importing!');
	// 		return;
	// 	}

	// 	navigate(`/import/${nasId}/${selectedCurrency.id}`);
	// }

	function handleNASWipe(nas: NAS) {
		setNASWipe(nas);
		handleWipe();
	}

	function handleWipe() {
		if (!selectedISP) return;
		setConfirmWipe(true);
	}

	async function handleWipeOk() {
		if (!selectedISP) return;
		setConfirmWipe(false);
		await fetchWipe(selectedISP.id, selectedISP.name, nasWipe?.id);
	}

	function handleSuccessVanish() {
		setSuccess(undefined);
	}

	useEffect(() => {
		if (!wipeResult) return;
		setSuccess(wipeResult);
	}, [wipeResult]);

	const dataTableFields: DataTableField<NAS>[] = [
		{
			title: '',
			property: 'check',
			extractor: (row) => (
				<Input
					type="checkbox"
					value={String(!!selectedNAS?.find((nas) => nas.id === row.id) || false)}
					onChange={handleSelectNAS(row)}
				/>
			),
		},
		{
			title: 'Status',
			property: 'status',
			extractor: (row) => (
				<Status
					status={connectionStatus?.find((nas) => nas.nas_id === row.id)}
					loading={loadingConnectionStatus}
				/>
			),
		},
		{ title: 'Name', property: 'name' },
		{ title: 'IP', property: 'api_url' },
		{ title: 'Username', property: 'username' },
		{
			title: 'RouterOS Version',
			property: 'version',
			extractor: (row) => {
				return connectionStatus?.find((nas) => nas.nas_id === row.id)?.connection.version;
			},
		},
		{
			title: 'Actions',
			property: 'actions',
			extractor: (row) => {
				return (
					<Button
						icon="UilTrashAlt"
						variant="danger"
						style="roundedOutline"
						size="xs"
						onClick={() => handleNASWipe(row)}
						disabled={wipeLoading || !selectedISP}
					/>
				);
			},
		},
		// {
		// 	title: 'From *.txt',
		// 	property: 'import-action',
		// 	extractor: (row) => {
		// 		return (
		// 			<div className="flex flex-col gap-2 items-start">
		// 				<Button
		// 					icon="UilAlignCenter"
		// 					variant="extraLight"
		// 					style="roundedOutline"
		// 					size="xs"
		// 					onClick={() => handleImportFromTxt(row.id)}
		// 				/>
		// 			</div>
		// 		);
		// 	},
		// },
	];

	const logsSummary = importLogs && summarizeLogs(importLogs);

	return (
		<WrapperPage
			title="Import NAS"
			error={error}
			success={success ? { title: 'Ok', message: success } : undefined}
			onVanish={handleSuccessVanish}
		>
			<div className="flex flex-col flex-grow justify-between">
				<div>
					<div className="m-4 flex flex-col gap-4">
						<WhiteBox>
							<DataTable
								style="condensed"
								loading={nasLoading}
								fields={dataTableFields}
								rows={nasResponse?.rows ? nasResponse.rows : []}
							/>
						</WhiteBox>
						{(importLoading || importLogs) && (
							<WhiteBox className={`flex flex-col gap-2 ${importLoading ? 'animate-pulse' : ''}`}>
								<Selection
									tabs={[
										{ key: 'all', label: 'All' },
										{ key: 'warnings', label: 'Warnings' },
										{ key: 'errors', label: 'Errors' },
									]}
									active={selectedTab}
									onSelect={handleSelectedLevel}
								/>
								<Logs
									logs={importLogs?.map((line) => `${line}\n`) || ['INFO: Importing...']}
									levels={levelsFromSelectedTab(selectedTab)}
								/>
								<div className="flex flex-row gap-2">
									{logsSummary && logsSummary['WARNING'] && (
										<Bullet type="warning" title={`${logsSummary['WARNING']} warnings`} />
									)}
									{logsSummary && logsSummary['ERROR'] && (
										<Bullet type="danger" title={`${logsSummary['ERROR']} errors`} />
									)}
								</div>
							</WhiteBox>
						)}
					</div>
				</div>
				<StickyBottom>
					<div className="flex items-center gap-2 px-4 h-24">
						<Button
							text="Test Connection"
							variant="light"
							icon={loadingConnectionStatus ? { type: 'UilSpinner', spin: true } : 'UilLaptopConnection'}
							onClick={handleTestConnection}
							style="roundedOutline"
							disabled={
								loadingConnectionStatus ||
								importLoading ||
								!nasResponse?.rows ||
								selectedNAS.length === 0
							}
						/>
						<Button
							text="Test Import"
							variant="success"
							icon={importLoading ? { type: 'UilSpinner', spin: true } : 'UilCloudCheck'}
							onClick={handleImport('testing')}
							style="roundedOutline"
							disabled={importLoading || !nasResponse?.rows || !connectionStatus}
						/>
						<Button
							text="Import"
							variant="warning"
							icon={importLoading ? { type: 'UilSpinner', spin: true } : 'UilUploadAlt'}
							onClick={handleConfirmImport}
							style="roundedOutline"
							disabled={importLoading || !nasResponse?.rows || !connectionStatus}
						/>
						<div className="w-64">
							<Select
								options={
									currencyResponse?.rows.map((currency) => ({
										key: currency.id,
										label: currency.name,
									})) || []
								}
								placeholder={'Select a Currency before import'}
								value={selectedCurrency?.id || ''}
								onChange={(key) =>
									setSelectedCurrency(currencyResponse?.rows.find((currency) => currency.id === key))
								}
							/>
						</div>
						<Button
							text="Wipe data"
							variant="danger"
							icon={wipeLoading ? { type: 'UilSpinner', spin: true } : 'UilTrashAlt'}
							onClick={handleWipe}
							style="roundedOutline"
							disabled={wipeLoading || !selectedISP}
						/>
						{confirmWipe && selectedISP && (
							<ConfimationBox
								title="Action Confirmation"
								message={
									nasWipe
										? `Are you sure you want to WIPE the NAS ${nasWipe.name}?`
										: 'Are you sure you want to WIPE this ISP?'
								}
								confirmationText={selectedISP.name}
								onOk={handleWipeOk}
								onCancel={() => setConfirmWipe(false)}
							/>
						)}
						{confirmImport && selectedISP && (
							<ConfimationBox
								title="Action Confirmation"
								message="Are you sure you want to IMPORT data into this ISP?"
								confirmationText={selectedISP.name}
								onOk={handleImport('commitment')}
								onCancel={() => setConfirmImport(false)}
							/>
						)}
						{alertBox && !error && (
							<AlertBox
								title={alertBox.title}
								message={alertBox.message}
								type={alertBox.type}
								onOk={() => setAlertBox(undefined)}
							/>
						)}
					</div>
				</StickyBottom>
			</div>
			{importAlertMessage && (
				<AlertBox
					type="warning"
					title="Import"
					message={importAlertMessage}
					onOk={() => setImportAlertMessage(undefined)}
				/>
			)}
		</WrapperPage>
	);
}
