import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IconType } from '@iconscout/react-unicons';

import UilIcon from './UilIcon';

interface IconButtonProps {
	icon?: IconDefinition;
	iconType?: IconType;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function IconButton({ icon, iconType, onClick }: IconButtonProps) {
	if (icon && iconType) throw Error('Escolha entre {icon} ou {iconType}. Nunca as duas!');
	return (
		<button
			className="flex items-center justify-center min-w-[40px] w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary-100 hover:text-primary-600 dark:text-subtitle-dark max-md:dark:hover:bg-box-dark-up cursor-pointer max-md:dark:bg-box-dark-up"
			id="slim-toggler"
			onClick={onClick}
		>
			{icon && <FontAwesomeIcon icon={icon} />}
			{iconType && <UilIcon type={iconType} className="w-4 h-4" />}
		</button>
	);
}
