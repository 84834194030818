import React, { useEffect } from 'react';
import FormGroup from '../slices/FormGroup';
import { useNavigate } from 'react-router-dom';
import { UilSpinner } from '@iconscout/react-unicons';
import { useAuth } from '../hooks/useAuth';

export default function LoginPage() {
	const navigate = useNavigate();
	const { login, ready, isAuthenticated } = useAuth();

	useEffect(() => {
		if (!ready) return;
		if (isAuthenticated) return navigate('/');
		login();
	}, [ready, isAuthenticated]);

	return (
		<div className="flex flex-col items-center gap-8">
			<div className="w-full">
				<FormGroup title="Login" titleAlign="center">
					<div className="flex gap-2 items-center">
						<UilSpinner className="animate-spin" />
						<h1 className="text-sm">
							Wait for login operation to <b>finish</b>!
						</h1>
					</div>
				</FormGroup>
			</div>
		</div>
	);
}
